import { gql } from '../__generated__/gql';

export const V2_GET_INSIGHT = gql(/* GraphQL */ `
  query V2GetInsight($id: UUID!) {
    insightV2(id: $id) {
      id
      messages {
        id
        content
        createdAt
        sender
        assets {
          id
          name
          url
          description
        }
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      progress
    }
  }
`);
