import { gql } from '../__generated__/gql';

export const GET_THEME_MESSAGES = gql(/* GraphQL */ `
  query GetThemeMessages(
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
    $filter: ThemeMessagesFilterInput!
    $orderBy: ThemeMessageOrder
  ) {
    themeMessages(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
      orderBy: $orderBy
    ) {
      items {
        aiProcessedText
        id
        text
        createdAt
        contact {
          id
          firstName
          lastName
          email
          phone
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`);
