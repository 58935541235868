import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chip } from '@/shared-ui';
import { useSidebar } from './manager-sidebar.hooks';

interface Props extends React.ComponentProps<'a'> {
  alert?: number | null;
  icon: IconDefinition;
}

export default function AnchorLink({ alert, children, icon, ...props }: Props) {
  const { sidebarState } = useSidebar();

  return (
    <div>
      <a
        data-state={sidebarState}
        className="group relative flex w-full p-4 text-left text-zinc-400 !outline-none !ring-0 transition-all duration-200 hover:bg-gradient-to-r hover:from-rose-300/10 hover:to-indigo-300/10  focus-visible:ring-offset-0 data-[state=open]:bg-gradient-to-r data-[state=open]:from-rose-300/15 data-[state=open]:to-indigo-300/15 data-[state=open]:after:absolute data-[state=open]:after:right-0 data-[state=open]:after:top-0 data-[state=open]:after:h-full data-[state=open]:after:w-1 data-[state=open]:after:rounded-l-xl data-[state=open]:after:bg-gradient-to-b data-[state=open]:after:from-rose-300/80 data-[state=open]:after:to-indigo-300/80"
        {...props}
      >
        <div className="flex w-11 items-center justify-center">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>

        <div
          data-state={sidebarState}
          className="w-0 overflow-hidden truncate font-medium tracking-wide group-hover/sidebar:w-[calc(100%-4.75rem)]  data-[state=fixed]:w-[calc(100%-4.75rem)]"
        >
          {children}
        </div>

        {typeof alert === 'number' ? (
          <div
            data-state={sidebarState}
            className="absolute right-1 top-1 group-hover/sidebar:right-4 group-hover/sidebar:top-auto data-[state=fixed]:right-4 data-[state=fixed]:top-auto"
          >
            <Chip content={alert} />
          </div>
        ) : null}
      </a>
    </div>
  );
}
