import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import ProjectResponseRankingItem from './project-response-ranking-item';
import { useProjectResponseRanking } from './project-response-ranking.hooks';
import { ProjectResponseAssetItem } from './project-response-asset-item';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

interface ProjectResponseRankingProps {
  values: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange?: (values: string) => void;
  readonly?: boolean;
  assets?: ProjectResponseComponentProps['assets'];
}

export default function ProjectResponseRanking({
  value,
  values,
  onChange,
  readonly,
  assets,
}: ProjectResponseRankingProps) {
  const { valueAsArray, valueIndexes, sensors, handleDragEnd } =
    useProjectResponseRanking({ value, values, onChange, readonly });

  if (readonly) {
    return (
      <div className="flex flex-col gap-2">
        {assets && assets?.length > 0 && assets[0]?.url && (
          <ProjectResponseAssetItem asset={assets[0]} />
        )}
        <div className="ms-5 flex flex-col items-start justify-center gap-2 rounded-lg border border-gray-200 bg-white p-4">
          {valueAsArray.map((value, index) => (
            <ProjectResponseRankingItem
              key={value.value}
              value={value.value}
              tag={String.fromCharCode(65 + valueIndexes[index])}
              disabled
              id={value.value}
              label={value.label}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={valueAsArray.map((e) => e.value)}>
        <div className="ms-5 flex touch-none flex-col items-start justify-center gap-2 rounded-lg border border-gray-200 bg-white p-4">
          {assets && assets?.length > 0 && assets[0]?.url && (
            <ProjectResponseAssetItem asset={assets[0]} />
          )}
          {valueAsArray.map((value, index) => (
            <ProjectResponseRankingItem
              key={value.value}
              value={value.value}
              tag={String.fromCharCode(65 + valueIndexes[index])}
              id={value.value}
              label={value.label}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
