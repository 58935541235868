import { gql } from '../__generated__/gql';

export const ADD_INSIGHT_PREVIEW = gql(/* GraphQL */ `
  mutation AddInsightPreview($input: AddInsightPreviewInput!) {
    addInsightPreview(input: $input) {
      id
      messages {
        id
        createdAt
        sender
        text
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      progress
      surveyId
    }
  }
`);
