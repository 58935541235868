import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import { ProjectResponseComponentProps } from './project-response-renderer.type';
import ProjectResponseSelect from './project-response-select';

export type SelectPayload = {
  options: {
    label: string;
    value: string;
  }[];
};

export function ProjectResponseMultiSelectRendererComponent({
  text,
  options = [],
  value,
  onChange,
  readonly,
  assets,
}: ProjectResponseComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <ProjectResponseOpenEndRendererComponent text={text} />
      <ProjectResponseSelect
        onClick={(value) => onChange?.(value)}
        options={options}
        value={value ?? ''}
        multiselect
        readonly={readonly}
        assets={assets}
      />
    </div>
  );
}
