import { gql } from '../__generated__/gql';

export const GET_PROJECT = gql(/* GraphQL */ `
  query getProject($id: UUID!) {
    project(id: $id) {
      id
      published
      questions {
        aiConvoDepth
        aiInsights
        assets {
          id
          name
          url
          description
        }
        id
        multipleSelect
        options {
          id
          label
          value
        }
        randomize
        required
        text
        type
      }
      redirectUrl
      introText
      title
      type
    }

    projectOrgSettings(projectId: $id) {
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
