export const imageFormatsLabels: Record<string, string> = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/svg+xml': 'SVG',
  'image/webp': 'WEBP',
};

export const videoFormatsLabels: Record<string, string> = {
  'video/mp4': 'MP4',
};

export function getImageDimensions(file: File) {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = URL.createObjectURL(file);
  });
}

export function formatAllowedFilesAndDimension({
  allowedTypes,
  dimension,
}: {
  allowedTypes?: string[];
  dimension?: { width: number; height: number };
}) {
  const allowedTypesString =
    allowedTypes
      ?.map((type) =>
        type.startsWith('video/')
          ? videoFormatsLabels[type]
          : imageFormatsLabels[type]
      )
      .join(', ')
      .replace(/, ([^,]*)$/, ' or $1') ?? '';
  const dimensionString = dimension
    ? `(Max. ${dimension.width}x${dimension.height}px)`
    : '';
  return `${allowedTypesString} ${dimensionString}`.trim();
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}
