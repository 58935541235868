import { gql } from '../__generated__/gql';

export const PREVIEW_URL = gql(/* GraphQL */ `
  query PreviewUrl($input: UrlPreviewInput!) {
    previewUrl(input: $input) {
      title
      description
      image
    }
  }
`);
