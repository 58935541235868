import { Navigate } from 'react-router-dom';
import { cn, ModalLayout, Skeleton } from '@/shared-ui';
import { useOnboardingQuestions } from './manager-onboarding-questions.hooks';
import { useOnboarding } from './manager-onboarding.hooks';
import { RenderButtonGroup } from './manager-onboarding-questions-render-button-group';
import { FormProvider } from 'react-hook-form';

export default function OnboardingQuestions() {
  const {
    questions,
    onSubmit,
    isLoading: isQuestionsLoading,
    isSubmitting,
    error,
    methods,
  } = useOnboardingQuestions();
  const { companyInfo, isLoading: isCompanyInfoLoading } = useOnboarding();

  const isLoading = isQuestionsLoading || isCompanyInfoLoading;

  const selectedQuestionId = methods.watch('selectedQuestionId');

  const shouldRedirectToSurvey =
    !!companyInfo?.insightsCount && companyInfo.insightsCount > 0;

  if (shouldRedirectToSurvey) {
    return <Navigate replace to={'/home/onboarding/survey'} />;
  }

  if (!isLoading) {
    if (companyInfo?.insightsCount) {
      return <Navigate replace to={'/home'} />;
    }
  }

  if (isLoading) {
    return (
      <ModalLayout>
        <div className="flex flex-col gap-4 p-5">
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} className="h-14 w-full" />
          ))}
        </div>
      </ModalLayout>
    );
  }

  return (
    <FormProvider {...methods}>
      <ModalLayout title="Pick a template question below to try out TheySaid's conversational AI survey">
        {error ? (
          <div className="flex flex-col items-center justify-center p-5">
            <p className="text-center text-red-600">
              {error.message ||
                'An error occurred while loading the questions.'}
            </p>
          </div>
        ) : (
          <form
            onSubmit={onSubmit}
            className="relative flex flex-col gap-5 overflow-y-hidden p-5"
          >
            <RenderButtonGroup
              options={questions}
              fieldName="selectedQuestionId"
            />
            <div className="w-full border-gray-200 pt-5">
              <button
                type="submit"
                className={cn(
                  'inline-flex h-14 w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2 text-base font-normal leading-normal text-white transition-opacity duration-200 ease-in-out',
                  (!selectedQuestionId || isSubmitting) &&
                    'cursor-not-allowed opacity-50'
                )}
                disabled={!selectedQuestionId || isSubmitting}
              >
                {isSubmitting && (
                  <div className="mr-2 h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
                )}
                Continue to test it out
              </button>
            </div>
          </form>
        )}
      </ModalLayout>
    </FormProvider>
  );
}
