import { ProjectResponseAssetItem } from './project-response-asset-item';
import { ProjectResponseComponentProps } from './project-response-renderer.type';
import ProjectResponseSelectItem from './project-response-select-item';

interface ProjectResponseSelectProps {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  multiselect?: boolean;
  onClick: (value: string) => void;
  readonly?: boolean;
  assets?: ProjectResponseComponentProps['assets'];
}

export default function ProjectResponseSelect({
  options,
  value,
  multiselect,
  onClick,
  readonly,
  assets,
}: ProjectResponseSelectProps) {
  const isValueSelected = (selectedValue: string) =>
    multiselect
      ? value.split(',').includes(selectedValue)
      : value === selectedValue;
  const handleClick = (clickedValue: string) => {
    if (multiselect) {
      if (isValueSelected(clickedValue)) {
        onClick(
          value
            .split(',')
            .filter((v) => v !== clickedValue)
            .join(',')
        );
      } else {
        if (value) {
          onClick([...value.split(','), clickedValue].join(','));
        } else {
          onClick(clickedValue);
        }
      }
    } else {
      onClick(clickedValue);
    }
  };

  return (
    <div className="ms-5 flex flex-col items-start justify-center gap-2 rounded-lg border border-gray-200 bg-white p-4">
      {assets && assets?.length > 0 && assets[0]?.url && (
        <ProjectResponseAssetItem asset={assets[0]} />
      )}
      {options.map((option, index) => (
        <div className="flex w-full flex-1" key={option.value}>
          <ProjectResponseSelectItem
            key={option.value}
            value={option.value}
            tag={String.fromCharCode(65 + index)}
            selected={isValueSelected(option.value)}
            onClick={() => handleClick(option.value)}
            disabled={readonly}
            label={option.label}
          />
        </div>
      ))}
    </div>
  );
}
