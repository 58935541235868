import { useQuery } from '@apollo/client';

import { GET_COMPANIES } from '@/manager-graphql';

export const useManagerOrganizations = () => {
  const { data, error, loading } = useQuery(GET_COMPANIES);

  return {
    error,
    loading,
    organizations: data?.companies || [],
    tableProps: {},
  };
};
