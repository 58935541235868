import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item } from '@radix-ui/react-dropdown-menu';
import { useAuth } from '@workos-inc/authkit-react';
import { faCircleCheck, faPlus } from '@fortawesome/pro-light-svg-icons';
import { StateMessageLoading } from '@/shared-ui';
import { useManagerOrganizations } from '@/manager-organizations';
import SidebarFooterOrganizationItemActions from './manager-sidebar.footer-organization-item-actions';
import { useNavigate } from 'react-router-dom';

interface SidebarFooterOrganizationsProps {
  setShowCreateDialog: (show: boolean) => void;
}

export default function SidebarFooterOrganizations({
  setShowCreateDialog,
}: SidebarFooterOrganizationsProps) {
  const client = useApolloClient();
  const { loading, organizations } = useManagerOrganizations();
  const { switchToOrganization, organizationId } = useAuth();
  const navigate = useNavigate();

  const handleSwitch = async (organizationId: string) => {
    switchToOrganization({ organizationId });
    await client.clearStore();
    navigate('/home');
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <Item asChild>
        <div className="flex w-full items-center justify-between rounded-t-xl border-b border-gray-100 px-4 py-2 text-gray-500 ring-0 transition-colors focus:outline-none focus-visible:ring-offset-0">
          Workspaces
          <button
            type="button"
            className="flex h-6 w-6 items-center justify-center rounded-lg border border-gray-200 text-gray-800"
            onClick={() => setShowCreateDialog(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </Item>
      {loading ? (
        <StateMessageLoading className="min-h-40" />
      ) : (
        organizations.map((org) => (
          <Item asChild key={org.id} disabled={org.workosId === organizationId}>
            <div
              className="group flex w-full cursor-pointer items-center justify-between rounded-xl  p-2 text-zinc-900 ring-0 transition-colors hover:bg-zinc-100 focus:bg-zinc-100 focus:outline-none focus-visible:ring-offset-0"
              onClick={() => handleSwitch(org.workosId)}
            >
              <div className="flex items-center gap-2 truncate">
                {org?.orgSettings?.logoUrl ? (
                  <img
                    className="h-6 w-6 rounded-full"
                    src={org.orgSettings.logoUrl}
                    alt="Avatar"
                  />
                ) : (
                  <div className="font-display flex h-6 w-6 items-center justify-center rounded-full bg-rose-100 text-sm uppercase text-red-700">
                    {org.name?.charAt(0)}
                  </div>
                )}
                <div className="max-w-36 truncate">{org.name}</div>
              </div>
              <div className="flex items-center" onClick={stopPropagation}>
                {org.workosId === organizationId && (
                  <FontAwesomeIcon
                    className="text-lime-700"
                    size="lg"
                    icon={faCircleCheck}
                  />
                )}
                <SidebarFooterOrganizationItemActions
                  organization={org}
                  isActive={org.workosId === organizationId}
                  className="invisible opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100"
                />
              </div>
            </div>
          </Item>
        ))
      )}
    </>
  );
}
