import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  bytesToSize,
  getImageDimensions,
  imageFormatsLabels,
} from './ui.media-upload.helpers';

export function useImageUpload(
  ref: React.Ref<{ reset: () => void }>,
  {
    allowedTypes,
    maxAllowedResolution,
    maxFileSize,
    onChange,
    onDelete,
    value,
  }: {
    allowedTypes: string[];
    imageFormatsLabels?: Record<string, string>;
    maxAllowedResolution?: { width: number; height: number };
    maxFileSize?: number;
    onChange: (file: File | null) => void;
    onDelete?: () => void;
    value: string | null;
  }
) {
  const [error, setError] = useState<string | null>(null);
  useImperativeHandle(ref, () => ({
    reset: () => {
      onChange(null);
      setImageSize(null);
      setImageName(null);
      setError(null);
    },
  }));
  const uploadRef = useRef<HTMLInputElement>(null);
  const promptUpload = () => uploadRef.current?.click();
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number | undefined;
    height: number | undefined;
  } | null>(null);
  const [imageSize, setImageSize] = useState<number | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.onload = () => {
        setImageDimensions({
          width: imageRef.current?.naturalWidth,
          height: imageRef.current?.naturalHeight,
        });
      };
    }
  }, [value, imageRef]);

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    handleChange(event.dataTransfer.files);
  };

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDropForBody = (event: DragEvent) => {
      event.preventDefault();
      setIsDragging(false);
    };

    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('dragleave', handleDragLeave);
    document.body.addEventListener('drop', handleDropForBody);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('dragleave', handleDragLeave);
      document.body.removeEventListener('drop', handleDropForBody);
    };
  }, []);

  const handleChange = async (files: FileList | null) => {
    setError(null);
    if (files && files[0]) {
      const file = files[0];
      if (allowedTypes.length && !allowedTypes.includes(file.type)) {
        const allowedTypesLabel = allowedTypes
          .map((type) => imageFormatsLabels[type])
          .join(', ')
          .replace(/, ([^,]+)$/, ' and $1');
        setError(`Uh Oh - Only ${allowedTypesLabel} files are supported`);
        return;
      }
      if (maxFileSize && file.size > maxFileSize) {
        setError(
          `Uh Oh - File larger than ${bytesToSize(maxFileSize)} Mb limit`
        );
        return;
      }
      if (maxAllowedResolution) {
        if (!file.type.startsWith('video/')) {
          const { width, height } = await getImageDimensions(file);
          if (
            width > maxAllowedResolution.width ||
            height > maxAllowedResolution.height
          ) {
            setError(
              `Uh Oh - dimensions exceed ${maxAllowedResolution.width}x${maxAllowedResolution.height} limit`
            );
            return;
          }
        }
      }
      setImageSize(file.size);
      setImageName(file.name);
      onChange(file);
    }
  };

  const handleTrashClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete?.();
    setImageDimensions(null);
    setError(null);
    setImageSize(null);
    setImageName(null);
  };

  return {
    handleChange,
    handleTrashClick,
    promptUpload,
    imageDimensions,
    imageSize,
    imageName,
    uploadRef,
    imageRef,
    error,
    isDragging,
    handleDrop,
  };
}
