import { gql } from '../__generated__/gql';

export const ADD_MESSAGE_PREVIEW = gql(/* GraphQL */ `
  mutation AddMessagePreview($input: AddMessagePreviewInput!) {
    addMessagePreview(input: $input) {
      sent {
        id
        createdAt
        sender
        text
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      reply {
        id
        createdAt
        sender
        text
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      surveyProgress
      chatSessionId
    }
  }
`);
