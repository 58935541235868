import { useMutation, useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CREATE_COMPANY_INFO, GET_COMPANY_INFO } from '@/manager-graphql';
import { useToast } from '@/shared-toast';
import { useAuth } from '@workos-inc/authkit-react';
import { CompanyInfoFormValues } from './manger-onboarding.helpers';

export const COMPANY_INFO_STORAGE_KEY = '@Theysaid:onboardingCompanyInfo';
const COMPANY_INFO_OPEN_STATUS_STORAGE_KEY =
  '@Theysaid:onboardingCompanyInfoOpenStatus';

export const useOnboardingCompanyInfo = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [addCompany, { error }] = useMutation(CREATE_COMPANY_INFO);
  const { addToast } = useToast();
  const client = useApolloClient();
  const { switchToOrganization, organizationId } = useAuth();

  const setSubmitting = useCallback((submitting: boolean) => {
    localStorage.setItem(
      COMPANY_INFO_OPEN_STATUS_STORAGE_KEY,
      JSON.stringify(submitting)
    );
  }, []);

  const handleSubmit = useCallback(
    async (input: CompanyInfoFormValues) => {
      setSubmitting(true);

      // call n8n webhook to push other data to hubspot
      fetch(import.meta.env.VITE_N8N_HUBSPOT_CONTACT_UPDATE_WEBHOOK, {
        method: 'POST',
        body: JSON.stringify({
          email: user?.email,
          ...input,
          tryingFor: input.tryingFor.join(','),
        }),
        mode: 'cors',
      }).catch((e) => {
        Sentry.captureException(
          `Error calling N8N webhook: ${e.message}, User: ${user?.email}`
        );
      });

      await addCompany({
        onCompleted: async (data) => {
          // If we have organizations but no active one, switch to the first org
          if (!organizationId && data?.createCompanyInfo?.workosId) {
            await switchToOrganization({
              organizationId: data.createCompanyInfo.workosId,
            });
            await client.clearStore();
          }

          setSubmitting(false);
          // Navigate to questions dialog
          localStorage.removeItem(COMPANY_INFO_STORAGE_KEY);
          localStorage.removeItem(COMPANY_INFO_OPEN_STATUS_STORAGE_KEY);
          navigate('/home/onboarding/questions');
        },
        onError: (error) => {
          addToast({
            title: error.message,
            type: 'error',
          });
          setSubmitting(false);
          if (!error?.message?.includes('Failed to fetch')) {
            throw error;
          }
        },
        update: (cache, { data }) => {
          if (data?.createCompanyInfo) {
            cache.modify({
              fields: {
                companyInfo() {
                  return cache.writeQuery({
                    data: { companyInfo: data.createCompanyInfo },
                    query: GET_COMPANY_INFO,
                  });
                },
              },
            });
          }
        },
        variables: {
          input: {
            website: input?.website,
          },
        },
      });
    },
    [
      addCompany,
      navigate,
      setSubmitting,
      user?.email,
      addToast,
      client,
      switchToOrganization,
      organizationId,
    ]
  );

  const onClear = useCallback(() => {
    localStorage.removeItem(COMPANY_INFO_STORAGE_KEY);
    localStorage.removeItem(COMPANY_INFO_OPEN_STATUS_STORAGE_KEY);
  }, []);

  return {
    error: error?.message !== 'Invalid website' ? error?.message : undefined,
    isInvalidWebsiteError: error?.message === 'Invalid website',
    onSubmit: handleSubmit,
    isSubmitting:
      localStorage.getItem(COMPANY_INFO_OPEN_STATUS_STORAGE_KEY) === 'true',
    onClear,
  };
};
