import { gql } from '../__generated__/gql';

export const GET_MESSAGES_V2 = gql(/* GraphQL */ `
  query GetMessagesV2($after: Cursor, $filter: MessageListFilterInputV2!) {
    messagesV2(after: $after, filter: $filter) {
      items {
        createdAt
        id
        assets {
          id
          name
          url
          description
        }
        metadata {
          isConversationOver
          multipleSelect
          type
          options {
            label
            value
          }
          required
        }
        sender
        text
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);
