import { gql } from '../__generated__/gql';

export const GET_INSIGHTS_V2_BY_MESSAGE = gql(/* GraphQL */ `
  query GetInsightsV2ByMessage($messageId: UUID!) {
    insightByMessageId(messageId: $messageId) {
      id
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      createdAt
      lastUserResponse {
        text
      }
      msgRead
      signal
      hasMessages
      shareId
      project {
        title
        id
        type
      }
    }
  }
`);
