import {
  ComponentProps,
  createElement,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

import { ProjectResponseMessageRenderer } from '@/project-response-message-renderers';
import { Chat } from '@/shared-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  formatUserAnswer,
  getAnsweredValueForAiMessage,
  getRelatedAiMessageToUserAnswer,
  getTypeOfRenderer,
} from './project-response-chat.helpers';
import { messageFormSchema } from './project-response-form.helpers';
import { MessageFormValues } from './project-response.hooks';

export type ChatMessage = ComponentProps<typeof Chat>['messages'][number];

function useSendMessageForm() {
  const form = useForm<MessageFormValues>({
    resolver: zodResolver(messageFormSchema),
    defaultValues: { content: '' },
  });

  return form;
}

export function useProjectChat({
  messages,
  showWarning,
  readonly,
}: {
  messages: Array<ChatMessage>;
  showWarning: boolean;
  readonly?: boolean;
}) {
  const [showWarn, dismissWarn] = useReducer((prev) => !prev, showWarning);
  const formValue = useSendMessageForm();
  const { setValue } = formValue;
  const handleChange = (value: string) => {
    setValue('content', value);
  };
  const content = formValue.watch('content');
  const lastAssistantMessage = useMemo(
    () => [...messages].reverse().find((m) => m.sender === 'assistant'),
    [messages]
  );

  const reorderOptions = useCallback(
    (options: ChatMessage['options'], typeOfRenderer: ChatMessage['type']) => {
      if (!options?.length) return [];

      if (typeOfRenderer !== 'multipleChoice') return options;

      const otherOptionIndex = options.findIndex((option) =>
        option?.value?.includes('other')
      );

      if (otherOptionIndex === -1 || otherOptionIndex === options.length - 1) {
        return options;
      }

      return [
        ...options.slice(0, otherOptionIndex),
        ...options.slice(otherOptionIndex + 1),
        options[otherOptionIndex],
      ];
    },
    []
  );

  function renderAssistantMessage(message: ChatMessage) {
    const typeOfRenderer = getTypeOfRenderer(message);
    const options = reorderOptions(message?.options, typeOfRenderer);

    return createElement(ProjectResponseMessageRenderer, {
      type: typeOfRenderer,
      text: message.text,
      onChange: handleChange,
      value: getAnsweredValueForAiMessage(message, messages) ?? content,
      options,
      readonly: readonly || lastAssistantMessage?.id !== message.id,
      assets: message.assets,
    });
  }

  function formatUserMessage(message: ChatMessage) {
    const relatedMessage = getRelatedAiMessageToUserAnswer(
      messages.findIndex((e) => e.id === message.id),
      messages
    );
    if (!relatedMessage) return message.text;
    const typeOfRenderer = getTypeOfRenderer(relatedMessage);
    const options = reorderOptions(relatedMessage.options, typeOfRenderer);

    return formatUserAnswer(typeOfRenderer, message.text, options);
  }

  return {
    showWarn,
    dismissWarn,
    renderAssistantMessage,
    lastAssistantMessage,
    formatUserMessage,
    formValue,
    content,
  };
}
