/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetInsightByShare($id: UUID!) {\n    insightByShare(id: $id) {\n      id\n      shareId\n      messages {\n        id\n        content\n        createdAt\n        sender\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n": types.GetInsightByShareDocument,
    "\n  query getProject($id: UUID!) {\n    project(id: $id) {\n      id\n      published\n      questions {\n        aiConvoDepth\n        aiInsights\n        assets {\n          id\n          name\n          url\n          description\n        }\n        id\n        multipleSelect\n        options {\n          id\n          label\n          value\n        }\n        randomize\n        required\n        text\n        type\n      }\n      redirectUrl\n      introText\n      title\n      type\n    }\n\n    projectOrgSettings(projectId: $id) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n": types.GetProjectDocument,
    "\n  mutation V2AddInsight($input: AddInsightV2Input!) {\n    addInsightV2(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n": types.V2AddInsightDocument,
    "\n  mutation V2AddMessage($input: AddMessageV2Input!) {\n    addMessageV2(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      reply {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      surveyProgress\n    }\n  }\n": types.V2AddMessageDocument,
    "\n  query V2GetInsight($id: UUID!) {\n    insightV2(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n": types.V2GetInsightDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetInsightByShare($id: UUID!) {\n    insightByShare(id: $id) {\n      id\n      shareId\n      messages {\n        id\n        content\n        createdAt\n        sender\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"): (typeof documents)["\n  query GetInsightByShare($id: UUID!) {\n    insightByShare(id: $id) {\n      id\n      shareId\n      messages {\n        id\n        content\n        createdAt\n        sender\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProject($id: UUID!) {\n    project(id: $id) {\n      id\n      published\n      questions {\n        aiConvoDepth\n        aiInsights\n        assets {\n          id\n          name\n          url\n          description\n        }\n        id\n        multipleSelect\n        options {\n          id\n          label\n          value\n        }\n        randomize\n        required\n        text\n        type\n      }\n      redirectUrl\n      introText\n      title\n      type\n    }\n\n    projectOrgSettings(projectId: $id) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n"): (typeof documents)["\n  query getProject($id: UUID!) {\n    project(id: $id) {\n      id\n      published\n      questions {\n        aiConvoDepth\n        aiInsights\n        assets {\n          id\n          name\n          url\n          description\n        }\n        id\n        multipleSelect\n        options {\n          id\n          label\n          value\n        }\n        randomize\n        required\n        text\n        type\n      }\n      redirectUrl\n      introText\n      title\n      type\n    }\n\n    projectOrgSettings(projectId: $id) {\n      avatarUrl\n      logoUrl\n      brandColor\n      hideTheysaidLogo\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation V2AddInsight($input: AddInsightV2Input!) {\n    addInsightV2(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"): (typeof documents)["\n  mutation V2AddInsight($input: AddInsightV2Input!) {\n    addInsightV2(input: $input) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation V2AddMessage($input: AddMessageV2Input!) {\n    addMessageV2(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      reply {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      surveyProgress\n    }\n  }\n"): (typeof documents)["\n  mutation V2AddMessage($input: AddMessageV2Input!) {\n    addMessageV2(input: $input) {\n      sent {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      reply {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      surveyProgress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query V2GetInsight($id: UUID!) {\n    insightV2(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"): (typeof documents)["\n  query V2GetInsight($id: UUID!) {\n    insightV2(id: $id) {\n      id\n      messages {\n        id\n        content\n        createdAt\n        sender\n        assets {\n          id\n          name\n          url\n          description\n        }\n        metadata {\n          isConversationOver\n          multipleSelect\n          options {\n            label\n            value\n          }\n          required\n          type\n        }\n      }\n      contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      progress\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;