import { gql } from '../__generated__/gql';

export const V2_ADD_MESSAGE = gql(/* GraphQL */ `
  mutation V2AddMessage($input: AddMessageV2Input!) {
    addMessageV2(input: $input) {
      sent {
        id
        content
        createdAt
        sender
        assets {
          id
          name
          url
          description
        }
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      reply {
        id
        content
        createdAt
        sender
        assets {
          id
          name
          url
          description
        }
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      surveyProgress
    }
  }
`);
