import { ProjectChat } from '@/project-response';
import { MessageSender, Survey } from '@/survey-graphql';
import { ComponentProps } from 'react';

export const DEFAULT_ACCENT_COLOR = '#ef4444';
export const SAMPLE_SURVEY: Survey = {
  primaryQuestion: 'How satisfied are you with contest frequency?',
  ratings: [
    { label: 'Negative', value: 1, __typename: 'Rating', id: '1' },
    { label: '', value: 2, __typename: 'Rating', id: '2' },
    { label: '', value: 3, __typename: 'Rating', id: '3' },
    { label: '', value: 4, __typename: 'Rating', id: '4' },
    { label: 'Positive', value: 5, __typename: 'Rating', id: '5' },
  ],
  isExpired: false,
  __typename: 'Survey',
  company: {
    name: 'Company',
    website: 'https://www.company.com',
    __typename: 'Company',
  },
  id: '1',
};
export const SAMPLE_MESSAGES: ComponentProps<typeof ProjectChat>['messages'] = [
  {
    id: '1',
    text: 'How satisfied are you with our product?',
    createdAt: new Date(),
    sender: MessageSender.Assistant,
    type: 'multipleChoice',
    options: [
      { value: '1', label: 'Very dissatisfied' },
      { value: '2', label: 'Dissatisfied' },
      { value: '3', label: 'Neutral' },
      { value: '4', label: 'Satisfied' },
      { value: '5', label: 'Very satisfied' },
    ],
    multipleSelect: false,
  },
  {
    id: '2',
    text: 'Very satisfied',
    createdAt: new Date(),
    sender: MessageSender.User,
  },
  {
    id: '3',
    text: "That's great to hear! What features do you find most valuable?",
    createdAt: new Date(),
    sender: MessageSender.Assistant,
    type: 'openEnd',
  },
  {
    id: '4',
    text: 'I love the analytics dashboard and the easy-to-use interface. It saves me a lot of time!',
    createdAt: new Date(),
    sender: MessageSender.User,
  },
];

export const FIVE_MEGABYTES = 5 * 1024 * 1024;

export const DEFAULT_THEYSAID_LOGO_URL =
  '/survey/img/theysaid-logo-colored-horizontal.svg';
export const SAMPLE_PROGRESS = 0.7;
