import { gql } from '../__generated__/gql';

export const GET_PROJECT = gql(/* GraphQL */ `
  query GetProject($id: UUID!) {
    project(id: $id) {
      id
      title
      lastResponseDate
      published
      redirectUrl
      thankyouText
      introText
      learningGoal
      totalGrowthSignals
      totalInsights
      totalRiskSignals
      totalUnreadInsights
      avgMessageDepth
      questions {
        id
        type
        text
        required
        aiInsights
        assets {
          id
          name
          url
          description
        }
        aiConvoDepth
        multipleSelect
        randomize
        options {
          id
          label
          value
        }
      }
      tags {
        id
        name
        color
      }
      enableContactInfoCollection
      askContactInformation
    }
  }
`);

export const GET_PROJECT_LIGHTWEIGHT = gql(/* GraphQL */ `
  query GetProjectLightweight($id: UUID!) {
    project(id: $id) {
      id
      title
      type
      aiLearntText
      learningGoal
      projectThemes {
        id
        name
        sentiment
        totalInsights
        counterTheme {
          id
          name
          sentiment
          totalInsights
        }
      }
      questions {
        id
        text
        type
        options {
          label
          value
        }
      }
      totalInsights
    }
  }
`);

export const GET_PROJECT_PREVIEW = gql(/* GraphQL */ `
  query getProjectPreview($id: UUID!) {
    project(id: $id) {
      id
      published
      questions {
        aiConvoDepth
        aiInsights
        assets {
          id
          name
          url
          description
        }
        id
        multipleSelect
        options {
          id
          label
          value
        }
        randomize
        required
        text
        type
      }
      redirectUrl
      introText
      title
      type
    }

    orgSettings {
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
