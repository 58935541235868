import { gql } from '../__generated__/gql';

export const CREATE_WORKOS_ORG_AND_COMPANY = gql(/* GraphQL */ `
  mutation CreateWorkOsOrgAndCompany($input: WorkosAndCompanyCreateInput!) {
    createWorkosOrgAndCompany(input: $input) {
      id
      workosId
      name
      category
      description
      website
      hasOnboarded
      insightsCount
    }
  }
`);
