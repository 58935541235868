import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import { Chip, cn } from '@/shared-ui';
import { useSidebar } from './manager-sidebar.hooks';

interface SidebarItemProps {
  icon: IconDefinition;
  text: React.ReactNode;
  to: React.ComponentProps<typeof NavLink>['to'];
  alert?: number | null;
}

export default function SidebarItem({
  icon,
  text,
  to,
  alert,
}: SidebarItemProps) {
  const { sidebarState } = useSidebar();

  return (
    <li>
      <NavLink
        to={to}
        data-state={sidebarState}
        className={({ isActive }) =>
          cn(
            'relative flex h-12 items-center px-4 text-zinc-100 transition-all group-hover/sidebar:w-full data-[state=fixed]:w-full',
            isActive
              ? 'bg-gradient-to-r from-rose-300/15 to-indigo-300/15'
              : 'hover:bg-gradient-to-r hover:from-rose-300/10 hover:to-indigo-300/10'
          )
        }
      >
        {({ isActive }) => (
          <>
            <span className="absolute w-10 text-center">
              <FontAwesomeIcon icon={icon} size="lg" />
            </span>

            <span
              data-state={sidebarState}
              className="ml-10 w-0 overflow-hidden whitespace-nowrap font-sans font-normal tracking-wide group-hover/sidebar:inline group-hover/sidebar:w-40 data-[state=fixed]:inline data-[state=fixed]:w-40"
            >
              {text}
            </span>

            {typeof alert === 'number' ? (
              <div
                data-state={sidebarState}
                className="absolute right-1 top-1 group-hover/sidebar:right-4 group-hover/sidebar:top-auto data-[state=fixed]:right-4 data-[state=fixed]:top-auto"
              >
                <Chip content={alert} />
              </div>
            ) : null}
            {isActive ? (
              <span className="absolute right-0 top-0 z-10 h-full w-1 rounded-bl-md rounded-tl-md bg-gradient-to-b from-rose-300/80 to-indigo-300/80"></span>
            ) : null}
          </>
        )}
      </NavLink>
    </li>
  );
}
