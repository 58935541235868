import {
  faEllipsisV,
  faClose,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';

import { Button, cn } from '@/shared-ui';
import { useSidebarFooterOrganizationItemActions } from './manager-sidebar.footer-organization-item-actions.hooks';
import { SidebarFooterOrganizationWarning } from './manager-sidebar.footer-organizations-warning';
import { GetCompaniesQuery } from '@/manager-graphql';

interface Props {
  organization: GetCompaniesQuery['companies'][number];
  className?: string;
  isActive: boolean;
}

export default function SidebarFooterOrganizationItemActions({
  organization,
  className,
  isActive,
}: Props) {
  const {
    onLeaveWorkspace,
    onDeleteWorkspace,
    handleWarningClose,
    warningType,
    loading,
  } = useSidebarFooterOrganizationItemActions({
    organizationId: organization.id,
  });

  const handleLeaveWorkspace = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onLeaveWorkspace();
  };

  const handleDeleteWorkspace = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDeleteWorkspace();
  };

  return (
    <DropdownMenu>
      <SidebarFooterOrganizationWarning
        loading={loading}
        handleWarningClose={handleWarningClose}
        warningType={warningType}
        onLeaveWorkspace={onLeaveWorkspace}
        onDeleteWorkspace={onDeleteWorkspace}
        organization={organization}
      />
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={cn('h-auto w-auto p-1', className)}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="flex flex-col items-start space-y-1 rounded-lg bg-white p-1 shadow">
        <DropdownMenuItem asChild>
          <button
            className="flex w-full items-center gap-3 rounded bg-white p-2 text-sm font-normal leading-tight text-zinc-800 focus:outline-zinc-100 enabled:hover:bg-zinc-100"
            type="button"
            onClick={handleLeaveWorkspace}
          >
            <FontAwesomeIcon
              className="text-red-500"
              icon={faClose}
              size="lg"
            />
            <span>Leave workspace</span>
          </button>
        </DropdownMenuItem>
        {!isActive && (
          <DropdownMenuItem asChild>
            <button
              className="flex w-full items-center gap-3 rounded bg-white p-2 text-sm font-normal leading-tight text-zinc-800 focus:outline-zinc-100 enabled:hover:bg-zinc-100"
              type="button"
              onClick={handleDeleteWorkspace}
            >
              <FontAwesomeIcon
                className="text-base text-red-500"
                icon={faTrashCan}
              />
              <span>Delete workspace</span>
            </button>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
