import { gql } from '../__generated__/gql';

export const UPDATE_QUESTION_ASSET = gql(/* GraphQL */ `
  mutation UpdateQuestionAsset($input: UpdateQuestionAssetInput!) {
    updateQuestionAsset(input: $input) {
      id
      name
      url
      description
    }
  }
`);
