import { gql } from '../__generated__/gql';

export const UPDATE_ACTION_ITEM = gql(/* GraphQL */ `
  mutation UpdateActionItem($id: UUID!, $input: UpdateActionInput!) {
    updateActionItem(id: $id, input: $input) {
      id
      status
      text
      createdAt
    }
  }
`);
