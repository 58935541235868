import { useEffect, useRef } from 'react';

export function useScrollIntoView<T>(
  dep: T,
  containerRef?: React.RefObject<HTMLElement>
): React.MutableRefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const container = containerRef?.current ?? document.documentElement;
    container.scrollTo({
      top: ref.current?.offsetTop,
      behavior: 'smooth',
    });
  }, [dep, containerRef]);
  return ref as React.MutableRefObject<HTMLDivElement>;
}
