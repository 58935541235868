import { gql } from '../__generated__/gql';

export const GET_COMPANIES = gql(/* GraphQL */ `
  query GetCompanies {
    companies {
      id
      name
      workosId
      orgSettings {
        logoUrl
      }
    }
  }
`);
