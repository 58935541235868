import { gql } from '../__generated__/gql';

export const GET_INSIGHTS_V2 = gql(/* GraphQL */ `
  query GetInsightsV2(
    $first: Int
    $after: Cursor
    $before: Cursor
    $filter: InsightV2ListFilterInput!
  ) {
    insightsV2(first: $first, after: $after, before: $before, filter: $filter) {
      items {
        id
        contact {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
        lastUserResponse {
          text
        }
        msgRead
        signal
        hasMessages
        shareId
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`);

export const GET_INSIGHTS_V2_LIGHT_WEIGHT = gql(/* GraphQL */ `
  query GetInsightsV2LightWeight($filter: InsightV2ListFilterInput!) {
    insightsV2(filter: $filter) {
      items {
        id
        createdAt
      }
    }
  }
`);
