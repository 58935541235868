import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useToast } from '@/shared-toast';
import {
  DELETE_ORGANIZATION,
  GET_COMPANIES,
  LEAVE_ORGANIZATION,
} from '@/manager-graphql';
import { useManagerOrganizations } from '@/manager-organizations';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@workos-inc/authkit-react';

interface UseSidebarFooterOrganizationItemActionsParams {
  organizationId: string;
}

export const useSidebarFooterOrganizationItemActions = ({
  organizationId,
}: UseSidebarFooterOrganizationItemActionsParams) => {
  const client = useApolloClient();
  const [warningType, setWarningType] = useState<'leave' | 'delete' | null>(
    null
  );
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { organizations } = useManagerOrganizations();
  const { signOut, switchToOrganization } = useAuth();

  const [leaveOrganization, { loading: leaveLoading }] = useMutation(
    LEAVE_ORGANIZATION,
    {
      refetchQueries: [GET_COMPANIES],
      onCompleted: async () => {
        const remainingOrgs = organizations.filter(
          (org) => org.id !== organizationId
        );

        if (remainingOrgs.length === 0) {
          signOut();
          navigate('/logout');
        } else {
          const nextOrg = remainingOrgs[0];
          switchToOrganization({ organizationId: nextOrg.workosId });
          await client.clearStore();
          navigate('/home');
        }
      },
      onError: (error) => {
        addToast({
          title: error.message,
          type: 'error',
        });
      },
    }
  );

  const [deleteOrganization, { loading: deleteLoading }] = useMutation(
    DELETE_ORGANIZATION,
    {
      refetchQueries: [GET_COMPANIES],
      onError: (error) => {
        addToast({
          title: error.message,
          type: 'error',
        });
      },
    }
  );

  const handleWarningClose = () => {
    setWarningType(null);
  };

  const handleLeaveWorkspace = async (confirm?: boolean) => {
    if (!confirm) {
      setWarningType('leave');
    } else {
      await leaveOrganization({
        variables: { orgId: organizationId },
      });
      handleWarningClose();
    }
  };

  const handleDeleteWorkspace = async (confirm?: boolean) => {
    if (!confirm) {
      setWarningType('delete');
    } else {
      await deleteOrganization({
        variables: { orgId: organizationId },
      });
      handleWarningClose();
    }
  };

  return {
    onLeaveWorkspace: handleLeaveWorkspace,
    onDeleteWorkspace: handleDeleteWorkspace,
    handleWarningClose,
    warningType,
    loading: leaveLoading || deleteLoading,
  };
};
