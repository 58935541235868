import { cn } from '@/shared-ui';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { Item } from '@radix-ui/react-dropdown-menu';
import { NavLink, type NavLinkProps } from 'react-router-dom';

interface Props {
  icon: FontAwesomeIconProps['icon'];
  text: React.ReactNode;
  to: NavLinkProps['to'];
  className?: string;
}

export default function SidebarFooterMenuItem({
  icon,
  text,
  to,
  className,
}: Props) {
  return (
    <Item asChild>
      <NavLink
        to={to}
        className={cn(
          'w-full rounded-xl px-4 py-2 text-zinc-900 ring-0 transition-colors hover:bg-zinc-100 focus:outline-none focus-visible:ring-offset-0',
          className
        )}
      >
        <FontAwesomeIcon icon={icon} size="lg" />
        <span className="ml-3 w-full text-sm font-medium leading-tight">
          {text}
        </span>
      </NavLink>
    </Item>
  );
}
