import {
  faSpinner,
  faXmark,
  faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent } from '@/shared-ui';
import type { GetCompaniesQuery } from '@/manager-graphql';

type SidebarFooterOrganizationWarningProps = {
  handleWarningClose: () => void;
  warningType: 'leave' | 'delete' | null;
  onLeaveWorkspace: (confirm?: boolean) => void;
  onDeleteWorkspace: (confirm?: boolean) => void;
  loading: boolean;
  organization: GetCompaniesQuery['companies'][number];
};

export function SidebarFooterOrganizationWarning({
  handleWarningClose,
  warningType,
  loading,
  onLeaveWorkspace,
  onDeleteWorkspace,
  organization,
}: SidebarFooterOrganizationWarningProps) {
  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (warningType === 'leave') {
      onLeaveWorkspace(true);
    } else if (warningType === 'delete') {
      onDeleteWorkspace(true);
    }
  };

  const handleDialogClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog open={!!warningType} onOpenChange={handleWarningClose}>
      <DialogContent
        onClick={handleDialogClick}
        className="flex items-start gap-4 rounded-2xl p-6 shadow-xl"
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-500"
            size="lg"
          />
        </div>
        <div className="flex w-[calc(100%-60px)] flex-col gap-2">
          <div className="mt-1.5 flex flex-row items-center justify-between">
            <p className="text-xl font-semibold">
              {warningType === 'leave' ? 'Leave Workspace' : 'Delete workspace'}
            </p>
            <Button
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                handleWarningClose();
              }}
              className="h-6 w-6 p-0 hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </div>
          <p className="text-sm text-gray-700">
            {warningType === 'leave'
              ? 'If you leave this workspace, other users will still have access to it. However, you won’t be able to access it again unless you receive a new invitation.'
              : 'If you delete this workspace, all users will lose access to its data permanently.'}
          </p>
          <p className="my-2 text-sm text-gray-700">
            Are you sure you want to{' '}
            {warningType === 'leave' ? 'leave ' : 'delete '}
            {organization.name}?
          </p>

          <div className="flex flex-row justify-end gap-4">
            <Button
              disabled={loading}
              type="button"
              variant="outline"
              className="w-32"
              onClick={(e) => {
                e.stopPropagation();
                handleWarningClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              type="button"
              variant="primary"
              className="w-32 bg-red-500 text-white"
              onClick={handleConfirm}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : warningType === 'leave' ? (
                'Leave'
              ) : (
                'Delete'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
