import { gql } from '../__generated__/gql';

export const LIST_PROJECTS = gql(/* GraphQL */ `
  query ListProjects(
    $after: Cursor
    $before: Cursor
    $filter: ProjectListFilterInput
    $first: Int
    $last: Int
    $orderBy: ProjectOrder
  ) {
    projects(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      items {
        id
        title
        type
        lastResponseDate
        published
        redirectUrl
        thankyouText
        introText
        learningGoal
        totalGrowthSignals
        totalInsights
        totalRiskSignals
        totalUnreadInsights
        avgMessageDepth
        askContactInformation
        enableContactInfoCollection
        questions {
          id
          type
          text
          required
          aiInsights
          assets {
            id
            name
            url
            description
          }
          aiConvoDepth
          multipleSelect
          randomize
          questionOrder
          options {
            id
            label
            value
          }
          tags {
            id
            name
            color
          }
        }
        tags {
          id
          name
          color
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        total
      }
    }
  }
`);
