import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ADD_PROJECT,
  CreateProjectInput,
  LIST_ONBOARDING_QUESTION_TEMPLATES,
  ProjectType,
  QuestionType,
} from '@/manager-graphql';
import { useToast } from '@/shared-toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { questionsFormSchema } from './manager-onboarding-questions.helpers';
import { FormData } from './manager-onboarding-questions.types';
import { useMeasurement } from '@/measurement';

// New hook for managing home state
export const useOnboardingQuestions = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const measurement = useMeasurement();

  const { addToast } = useToast();

  const methods = useForm<FormData>({
    resolver: zodResolver(questionsFormSchema),
    mode: 'onChange',
  });

  const { data, error, loading } = useQuery(LIST_ONBOARDING_QUESTION_TEMPLATES);

  const [addProject] = useMutation(ADD_PROJECT);

  const handleAddProject = async (input: CreateProjectInput) => {
    setIsSubmitting(true);
    try {
      await addProject({
        variables: { input },
        onCompleted: (data) => {
          setIsSubmitting(false);
          const projectId = data.createProject?.id;
          if (projectId) {
            navigate(`/home/onboarding/survey/${projectId}`);
          } else {
            throw new Error('Survey ID not returned');
          }
        },
        onError: (error) => {
          setIsSubmitting(false);
          throw error;
        },
      });
    } catch (error) {
      setIsSubmitting(false);
      addToast({
        type: 'error',
        title: 'Failed to add survey. Please try again.',
      });
    }
  };

  const onSubmit = (formData: FormData) => {
    if (measurement) measurement.start_session_recording();
    const question = data?.onboardingQuestionTemplates.find(
      (question) => question.primaryQuestion === formData.selectedQuestionId
    );

    if (!question) return;
    handleAddProject({
      type: ProjectType.Pulse,
      title: question.tagName,
      published: true,
      questions: [
        {
          text: question.primaryQuestion,
          type: QuestionType.Rating,
          options: question.ratingLabels.map((e, idx) => ({
            value: (idx + question.ratingMinValue).toString(),
            label: e,
          })),
          aiInsights: true,
          aiConvoDepth: 1,
          tags: [
            {
              name: question.tagName,
            },
          ],
        },
      ],
    });
  };

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
    questions: data?.onboardingQuestionTemplates || [],
    isSubmitting,
    error,
    isLoading: loading,
  };
};
