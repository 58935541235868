import {
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
} from '@dnd-kit/core';

import { useSensors } from '@dnd-kit/core';


import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { ComponentProps, useEffect } from 'react';
import ProjectResponseRanking from './project-response-ranking';

export const useProjectResponseRanking = ({
  value,
  values,
  readonly,
  onChange,
}: ComponentProps<typeof ProjectResponseRanking>) => {
  const valueAsArray = value.split(',').map((value) => ({
    label: values.find((e) => e.value === value)?.label ?? '',
    value,
  }));
  const valueIndexes = valueAsArray.map((value) =>
    values.findIndex((e) => e.label === value.label)
  );

  useEffect(() => {
    if (value === '') {
      // - Using requestAnimationFrame ensures that the state update occurs in the next repaint, which is crucial for smoother UI transitions.
      // - Without it, ranking questions may not display options correctly in ProjectResponsePreview component.
      const frameId = requestAnimationFrame(() => {
        onChange?.(values.map((e) => e.value).join(','));
      });

      return () => {
        cancelAnimationFrame(frameId);
      };
    }
  }, [values, value, onChange]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;
    if (active?.id !== over?.id) {
      const oldIndex = valueAsArray.findIndex((e) => e.value === active.id);
      const newIndex = valueAsArray.findIndex((e) => e.value === over.id);
      const newItems = arrayMove(valueAsArray, oldIndex, newIndex);
      onChange?.(newItems.map((e) => e.value).join(','));
    }
  };

  const valuesToDisplay = readonly && value === '' ? values : valueAsArray;
  const valueIndexesToDisplay =
    readonly && value === '' ? values.map((_, i) => i) : valueIndexes;

  return {
    valueAsArray: valuesToDisplay,
    valueIndexes: valueIndexesToDisplay,
    sensors,
    handleDragEnd,
  };
};
