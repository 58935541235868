import { faLoader } from '@fortawesome/pro-light-svg-icons';

import StateMessage from './ui.state-message';
import { cn } from './ui.helpers';

const StateMessageLoading = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'flex min-h-[calc(100vh-240px)] min-w-full items-center',
        className
      )}
    >
      <StateMessage
        heading="Loading..."
        icon={faLoader}
        iconClassName="animate-spin-slow"
      />
    </div>
  );
};

export default StateMessageLoading;
