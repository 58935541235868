import { useToast } from '@/shared-toast';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  CREATE_WORKOS_ORG_AND_COMPANY,
  GET_COMPANIES,
} from '@/manager-graphql';
import {
  createOrganizationFormSchema,
  type CreateOrganizationFormValues,
} from './manager-organizations-create-organization.helpers';

export const useManagerOrganizationsCreateOrganization = () => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [createWorkosOrgAndCompany] = useMutation(
    CREATE_WORKOS_ORG_AND_COMPANY,
    {
      refetchQueries: [GET_COMPANIES],
      awaitRefetchQueries: true,
      update: (cache, { data }) => {
        if (data?.createWorkosOrgAndCompany) {
          const existingData = cache.readQuery({
            query: GET_COMPANIES,
          });

          cache.writeQuery({
            query: GET_COMPANIES,
            data: {
              companies: [
                ...(existingData?.companies || []),
                {
                  id: data.createWorkosOrgAndCompany.id,
                  name: data.createWorkosOrgAndCompany.name,
                  workosId: data.createWorkosOrgAndCompany.workosId,
                  orgSettings: null,
                },
              ],
            },
          });
        }
      },
      onError: (error) => {
        addToast({
          title: error.message,
          type: 'error',
        });
      },
    }
  );
  const { addToast } = useToast();

  const { formState, handleSubmit, register, reset } =
    useForm<CreateOrganizationFormValues>({
      resolver: zodResolver(createOrganizationFormSchema),
    });

  const handleSubmitAndClose = async (input: CreateOrganizationFormValues) => {
    await createWorkosOrgAndCompany({
      variables: { input },
    });

    closeButtonRef.current?.click();
  };

  const onSubmit = handleSubmit(handleSubmitAndClose);

  useEffect(() => {
    if (!formState.isSubmitting && formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState.isSubmitting, formState.isSubmitSuccessful, reset]);

  return {
    closeButtonRef,
    formState,
    register,
    onSubmit,
  };
};
