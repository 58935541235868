import { gql } from '../__generated__/gql';

export const UPDATE_QUESTION_V2 = gql(/* GraphQL */ `
  mutation UpdateQuestionV2($input: UpdateQuestionV2Input!) {
    updateQuestionV2(input: $input) {
      id
      type
      text
      required
      aiInsights
      assets {
        id
        name
        url
        description
      }
      aiConvoDepth
      multipleSelect
      randomize
      options {
        id
        label
        value
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
