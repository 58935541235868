import { gql } from '../__generated__/gql';

export const CREATE_COMPANY_INFO = gql(/* GraphQL */ `
  mutation CreateCompanyInfo($input: OrganizationCreateInput!) {
    createCompanyInfo(input: $input) {
      id
      name
      category
      description
      website
      hasOnboarded
      insightsCount
      workosId
    }
  }
`);
