import ProjectResponseRanking from './project-response-ranking';
import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

export function ProjectResponseRankingRendererComponent({
  text,
  options = [],
  value,
  onChange,
  readonly,
  assets,
}: ProjectResponseComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <ProjectResponseOpenEndRendererComponent text={text} />
      <ProjectResponseRanking
        values={options}
        value={value ?? ''}
        onChange={onChange}
        readonly={readonly}
        assets={assets}
      />
    </div>
  );
}
