import { useAuth } from '@workos-inc/authkit-react';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  AuthGuard,
  Loading,
  SignInPage,
  SignOutPage,
  SignUpPage,
} from '@/manager-auth';
import { ManagerBranding } from '@/manager-branding';
import { Home, HomeWrapper } from '@/manager-home';
import { Landing } from '@/manager-landing';
import {
  Onboarding,
  OnboardingCompanyInfo,
  OnboardingCompanyInfoSubmitting,
  OnboardingQuestions,
  OnboardingSurvey,
} from '@/manager-onboarding';
import Layout from '@/manager-sidebar';
import { StateMessageLoading } from '@/shared-ui';

const ApiAccessDocs = lazy(() => import('./pages/api-access-docs'));
const ApiAccessKeys = lazy(() => import('./pages/api-access-keys'));
const CompanySummary = lazy(() => import('./pages/company-summary'));
const CompanySummaryEdit = lazy(() => import('./pages/company-summary-edit'));
const ManagerInsights = lazy(() => import('./pages/manager-insights'));
const NotFound = lazy(() => import('./pages/not-found'));
const Organizations = lazy(() => import('@/manager-organizations'));
const Projects = lazy(() => import('@/manager-projects'));
const UserManagement = lazy(() => import('@/manager-user-management'));

export default function AppRoutes() {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="login" element={<SignInPage />} />
      <Route path="logout" element={<SignOutPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route
        path=""
        element={
          <Layout>
            <Suspense fallback={<StateMessageLoading />}>
              <AuthGuard component={HomeWrapper} />
            </Suspense>
          </Layout>
        }
      >
        <Route path="home">
          <Route index element={<AuthGuard component={Home} />} />
          <Route path="onboarding">
            <Route index element={<AuthGuard component={Onboarding} />} />
            <Route
              path="company-info"
              element={<AuthGuard component={OnboardingCompanyInfo} />}
            />
            <Route
              path="company-info-submitting"
              element={
                <AuthGuard component={OnboardingCompanyInfoSubmitting} />
              }
            />
            <Route
              path="questions"
              element={<AuthGuard component={OnboardingQuestions} />}
            />
            <Route
              path="survey/:id?"
              element={<AuthGuard component={OnboardingSurvey} />}
            />
          </Route>
          <Route path="company-summary">
            <Route index element={<AuthGuard component={CompanySummary} />} />
            <Route
              path="edit"
              element={<AuthGuard component={CompanySummaryEdit} />}
            />
          </Route>
        </Route>
        <Route path="projects">
          <Route index element={<AuthGuard component={Projects} />} />
          <Route
            path=":id"
            element={<AuthGuard component={ManagerInsights} />}
          />
        </Route>
        <Route
          path="organizations"
          element={<AuthGuard component={Organizations} />}
        />
        <Route
          path="user-management"
          element={<AuthGuard component={UserManagement} />}
        />
        <Route
          path="branding"
          element={<AuthGuard component={ManagerBranding} />}
        />
        <Route path="api-access">
          <Route index element={<Navigate to="keys" />} />
          <Route
            path="keys"
            element={<AuthGuard component={ApiAccessKeys} />}
          />
          <Route
            path="docs"
            element={<AuthGuard component={ApiAccessDocs} />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
